import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  // Uniform
  {
    path: '/profile/add-access-token',
    component: loadable(() => import('pages/profile/add-access-token')),
    exact: true,
  },
  {
    path: '/profile/add-identity',
    component: loadable(() => import('pages/profile/add-identity')),
    exact: true,
  },
  {
    path: '/profile',
    component: loadable(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/auth/sitecore/login',
    component: loadable(() => import('pages/auth/sitecore/login')),
    exact: true,
  },
  {
    path: '/auth/:providerId',
    component: loadable(() => import('pages/auth/oauth')),
    exact: true,
  },
  {
    path: '/insights',
    component: loadable(() => import('pages/insights')),
  },
  {
    path: '/sites/add',
    component: loadable(() => import('pages/sites/add-site')),
  },
  {
    path: '/sites/:siteId',
    component: loadable(() => import('pages/sites/site')),
  },
  {
    path: '/sites',
    component: loadable(() => import('pages/sites')),
  },
  {
    path: '/intent/categories/add',
    component: loadable(() => import('pages/intent/categories/add-category')),
  },
  {
    path: '/intent/categories/:categoryId',
    component: loadable(() => import('pages/intent/categories/category')),
  },
  {
    path: '/intent/categories',
    component: loadable(() => import('pages/intent/categories')),
  },
  {
    path: '/intent',
    component: loadable(() => import('pages/intent')),
  },
  {
    path: '/admin/connections',
    component: loadable(() => import('pages/admin/connections')),
  },
  {
    path: '/admin/services',
    component: loadable(() => import('pages/admin/services')),
  },
  // Default Pages
  {
    path: '/pages/login',
    component: loadable(() => import('pages/pages/login')),
    exact: true,
  },
  {
    path: '/pages/lockscreen',
    component: loadable(() => import('pages/pages/lockscreen')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/sites" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
