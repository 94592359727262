import React from 'react'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import styles from './style.module.scss'

@withRouter
class LoginLayout extends React.PureComponent {
  render() {
    const { children } = this.props
    // const { backgroundNumber, backgroundEnabled } = this.state

    return (
      <Layout>
        <Layout.Content>
          <div style={{ backgroundColor: '#1D3557' }}>
            <div className={styles.content}>{children}</div>
            <div className={`${styles.footer} text-center`} style={{ color: '#b8beca' }}>
              <p>&copy; 2020 Uniform. All rights reserved.</p>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default LoginLayout
