import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'

@connect(({ user }) => ({ user }))
class ProfileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  render() {
    const { loggedOut = false } = this.state
    if (loggedOut === true) {
      return <Redirect to="/" />
    }
    const { user } = this.props
    const menu = (
      <Menu selectable={false}>
        {user.name === null && (
          <Menu.Item>
            <FormattedMessage id="topBar.profileMenu.hello" />
            ,&nbsp;
            <strong>{user.name}</strong>
          </Menu.Item>
        )}
        {user.name === null && <Menu.Divider />}
        <Menu.Item>
          <div>
            <strong className="mr-1">
              <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
            </strong>
            Enterprise
          </div>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            {user.role}
          </div>
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.email" />:{' '}
            </strong>
            {user.email}
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Link to="/profile">Edit Profile</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <a href="" onClick={this.logout}>
            <i className={`${styles.menuIcon} icmn-exit`} />
            <FormattedMessage id="topBar.profileMenu.logout" />
          </a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div className={styles.dropdown}>
          <Avatar className={styles.avatar} shape="square" size="large" icon="user" />
        </div>
      </Dropdown>
    )
  }
}

export default ProfileMenu
