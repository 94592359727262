import React from 'react'
import styles from './style.module.scss'

const Footer = () => (
  <div className={`${styles.footer} text-center`}>
    <p>&copy; 2020 Uniform. All rights reserved.</p>
  </div>
)

export default Footer
